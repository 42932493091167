import {isNotNull} from "./Utils";

var HashMap = require('hashmap');

function generateAllergens(self){
    var allergensList = new HashMap();
    allergensList.set("1", [
        {
            "id": 1,
            "name": self.$t('gluten')
        },
        {
            "id": 2,
            "name": self.$t('shellfish')
        },
        {
            "id": 3,
            "name": self.$t('eggs')
        },
        {
            "id": 4,
            "name": self.$t('fishes')
        },
        {
            "id": 5,
            "name": self.$t('peanuts')
        },
        {
            "id": 6,
            "name": self.$t('soy')
        },
        {
            "id": 7,
            "name": self.$t('milk')
        },
        {
            "id": 8,
            "name": self.$t('nuts')
        },
        {
            "id": 9,
            "name": self.$t('celery')
        },
        {
            "id": 10,
            "name": self.$t('mustard')
        },
        {
            "id": 11,
            "name": self.$t('sesameSeeds')
        },
        {
            "id": 12,
            "name": self.$t('lupin')
        },
        {
            "id": 13,
            "name": self.$t('molluscs')
        }
    ]);
    return allergensList;
}

export function getAllergensList(self) {
    var allergensList = generateAllergens(self);
    var demoType = localStorage.getItem("DKC_univers");
    var res = {};
    if(isNotNull(allergensList.get(demoType))){
        res = allergensList.get(demoType);
    }
    return res;
}
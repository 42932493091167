<template>
    <div id="account" style="padding-top: 60px;">
        <div class="pageHeader basketHeader">
            <div class="contentHeader">
                <a href="javascript:void(0)" @click="handleBack" class="backBtn">
                    <img src="@/assets/img/back.png">
                </a>
                <span>{{$t('accountInformations')}}</span>
            </div>
        </div>
        <b-form class="custom-form" @submit="onSubmit">
            <div role="tablist">
                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block href="#" class="form-accordion-header" v-b-toggle.account-general-infos>{{$t('accountEditGeneralInfos')}}</b-button>
                    </b-card-header>
                    <b-collapse id="account-general-infos" :visible="!showScannedCard"  accordion="account-infos-accordion" role="tabpanel">
                        <b-card-body>

                            <div class="custom-form-group">
                                <label for="firstname">{{$t('firstname')}} :</label>
                                <b-form-input id="firstname" ref="firstname" state="null" class="custom-input" v-model="form.firstName" type="text" />
                            </div>
                            
                            <div class="custom-form-group">
                                <label for="lastname">{{$t('lastname')}} :</label>
                                <b-form-input id="lastname" ref="lastname" state="null" class="custom-input" v-model="form.lastName" type="text" />
                            </div>
                            
                            <div class="custom-form-group">
                                <label for="email">{{$t('email')}} :</label>
                                <b-form-input id="email" ref="email" state="null" class="custom-input" v-model="form.email" type="email" />
                            </div>
                
                            <div class="custom-form-group">
                                <label for="address">{{$t('address')}} :</label>
                                <b-form-input id="address" ref="address" state="null" class="custom-input" v-model="form.address" type="text" />
                            </div>
                
                            <div class="custom-form-group">
                                <label for="cp">{{$t('zipcode')}} :</label>
                                <b-form-input id="cp" ref="cp" state="null" class="custom-input" v-model="form.cp" type="number" />
                            </div>
                
                            <div class="custom-form-group">
                                <label for="city">{{$t('city')}} :</label>
                                <b-form-input id="city" ref="city" state="null" class="custom-input" v-model="form.city" type="text" />
                            </div>
                
                            <div class="custom-form-group">
                                <label for="country">{{$t('country')}} :</label>
                                <b-form-input id="country" ref="country" state="null" class="custom-input" v-model="form.country" type="text" />
                            </div>

                            <div class="form-footer">
                                <b-button type="submit" class="btn-active" variant="primary">{{$t('save')}}</b-button>
                            </div>
                                
                        </b-card-body>
                    </b-collapse>
                </b-card>
            
                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block href="#" class="form-accordion-header" v-b-toggle.account-allergens>{{$t('accountEditAllergens')}}</b-button>
                    </b-card-header>
                    <b-collapse id="account-allergens" accordion="account-infos-accordion" role="tabpanel">
                        <b-card-body>
                            <div class="custom-form-group" id="allergensForm" v-if="allergensList.length > 0">
                                <label for="allergens">{{$t('allergens')}} :</label>
                                <b-form-select id="allergens" ref="allergens" multiple v-model="form.allergens">
                                    <option v-for="allergen in allergensList" :value="allergen.id" :key="allergen.id">{{allergen.name}}</option>
                                </b-form-select>
                            </div>

                            <div class="form-footer">
                                <b-button type="submit" class="btn-active" variant="primary">{{$t('save')}}</b-button>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            
                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block href="#" class="form-accordion-header" v-b-toggle.account-loyalty-card>{{$t('accountEditLoyalty')}}</b-button>
                    </b-card-header>
                    <b-collapse id="account-loyalty-card" :visible="showScannedCard" accordion="account-infos-accordion" role="tabpanel">
                        <b-card-body>
                            <div class="custom-form-group loyalty-card">
                                <div ref="loyaltyCardNumber" class="loyalty-card-ean mx-auto">
                                    <img v-show="form.loyaltyCardNumber.length>0" ref="loyaltyCardNumber" id="loyaltyCardNumber"/>
                                    <div v-show="form.loyaltyCardNumber.length == 0">
                                        {{$t('accountEditNoLoyaltyCard')}}
                                    </div>
                                </div>
                                <b-button variant="primary" class="btn-active loyalty-card-scan" @click="scanLoyaltyCard">{{$t('scanLoyaltyCard')}}</b-button>
                            </div>
                            <div class="form-footer">
                                <b-button type="submit" class="btn-active" variant="primary">{{$t('save')}}</b-button>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>
        </b-form>
    </div>
</template>

<script>
    import {isNotNull} from "@/assets/js/Utils"
    var Allergens = require("@/assets/js/Allergens");
    var Customer = require("@/assets/js/Customer");

    export default {
        name: "Account",
        data() {
            return {
                form: {
                    token: localStorage.getItem("DKC_fuel_token"),
                    firstName: "",
                    lastName: "",
                    email: "",
                    address: "",
                    cp: "",
                    city: "",
                    country: "",
                    allergens: [],
                    loyaltyCardNumber: ""
                },
                allergensList: {},
                fromRoute : null,
                showScannedCard : false
            }
        },
        beforeRouteEnter (to, from, next) {
            next(vm => {
                vm.fromRoute = from;
            })
        },
        mounted(){
            var self = this;
            this.allergensList = Allergens.getAllergensList(this);

            if(isNotNull(localStorage.getItem("DKC_formTemp"))){
                this.form = JSON.parse(localStorage.getItem("DKC_formTemp"));
                localStorage.removeItem("DKC_formTemp");
            }else{
                Customer.getCustomer(function(jsonCustomer){
                    if(isNotNull(jsonCustomer)){
                        var customer = JSON.parse(jsonCustomer);
                        self.form.firstName = customer.firstName || "";
                        self.form.lastName = customer.lastName || "";
                        self.form.email = customer.email || "";
                        if(isNotNull(customer.address)){
                            self.form.address = customer.address.address || "";
                            self.form.cp = customer.address.cp || "";
                            self.form.city = customer.address.city || "";
                            self.form.country = customer.address.country || "";
                        }
                        if(isNotNull(customer.allergens)){
                            self.form.allergens = JSON.parse(customer.allergens);
                        }
                    }
                });
            }
        },
        methods:{
            onSubmit(evt) {
                evt.preventDefault()
                Customer.updateCustomer(this.form);
                localStorage.setItem("DKC_customerEmail", this.form.email);
            },
            handleBack () {
                if (!isNotNull(this.fromRoute) || !isNotNull(this.fromRoute.name)) {
                    this.$router.push(localStorage.getItem("DKC_base") + '/');
                } else {
                    this.$router.back();
                }
            }
        }
    }
</script>

<style scoped>

</style>